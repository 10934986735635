import styled from "styled-components";


export const ProductInfoOverlay = styled.div`
  position: absolute;
  top: ${(props) => (props.size === "small" ? "50%" : "60%")};
  left: 15%;
  width: 65%;
  background-color:rgb(247, 244, 246);
  background-size: cover;
  border-radius: 50px;
  height: 40px;
  padding: 10px;
  z-index: 1;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s, transform 0.3s;

  @media (max-width: 768px) {
    position: absolute;
    top: ${(props) => (props.size === "one" ? "65%" : "40%")};
    left: 13%;
    width: 65%;
    background-color: white;
    border-radius: 50px;
    height: 30px;
    padding: 10px;
    z-index: 1;
    font-size: 11px;
    display: flex;
    justify-content: space-around;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s, transform 0.3s;
  

  }
`;

export const ProductInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const InfoText = styled.p`
  text-align: center;
  font-weight: 500;
  font-family: Thasadith;
  font-style: normal;
  line-height: normal;

`;



export const InfoValue = styled.span`
  display: block;
`;


export const ItemStore = styled.div`
  position: relative;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: stretch; 
  
  width: ${(props) =>
    props.size === "small"
      ? "25%"
      : props.size === "large"
      ? "50%"
      : props.size === "one"
      ? "100%"
      : "33.3%"};

  @media (max-width: 1000px) {
    width: ${(props) =>
      props.size === "small"
        ? "25%"
        : props.size === "large"
        ? "50%"
        : props.size === "one"
        ? "100%"
        : "33.3%"};
  }
`;

export const ItemTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  height: 100%; 
  overflow: hidden;
  padding: 5px;
  margin-bottom: 40px;

  img {
    width: 100%;
    object-fit: cover; 
    transition: transform 2.3s;
    cursor: pointer;
  }

  &:hover ${ProductInfoOverlay}:before {
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 0.3s;
  }
  &:hover ${ProductInfoOverlay} {
    opacity: 1;
    transform: scale(1);
  }

  @media (max-width: 768px) {
    flex-direction: column; 
  }
`;


export const ProductBrand = styled.label`
  font-size: 18px;
  color: #000;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 195px;
  @media (max-width: 768px) {
    font-size: 18px;
    color: #000;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 0;
    wordwrap: "break-word";
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    white-space: nowrap;
  }
`;

export const ProductName = styled.label`
  font-size: 14px;
  color: #000;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  wordwrap: "break-word";
  overflow: hidden;
  text-overflow: ellipsis;
  width: 195px;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 14px;
    color: #000;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 0;
    wordwrap: "break-word";
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    white-space: nowrap;
  }
`;


export const Price = styled.label`
  color: #000;
  font-family: Thasadith;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  align-self: flex-end;
  @media (max-width: 768px) {
    font-size: 14px;
    color: #000;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;



export const LineSeparator = styled.div`
  width: 100%;
  height: 1.2px;
  background-color: #000;
  margin: 10px 0;
`;

export const PrecoAntigo = styled.div`
    text-decoration:line-through;
    color:#888;
    font-weight:800;

`;

export const PrecoOriginal = styled.div`
    color:black;
`;

export const PrecoDesconto = styled.div`
    color:#41A922;
    font-weight:700;

`;


export const Desconto = styled.div`
    display: flex;
    align-items:center;
    gap: 10px;

`;