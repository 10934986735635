import styled from "styled-components";

export const Modal = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  position: absolute;
  z-index: 9999;
  height:100%;
  width:100%;
  @media (max-width: 1068px) {
      width: 305%;
     display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    right: 0;

  }
`;

export const Content = styled.form`
  padding: 40px;
  width: 100%;
  @media (max-width: 1068px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  color: #000;
  font-family: Thasadith;
  font-size: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px 0px 30px;
  width:100%;

  div {
    display: flex;
    flex-direction: column;
  }

  span {
    padding-bottom: 5px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding: 0px 1%;

    div {
      width: 180px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  margin-top:10px;

  @media (max-width: 1068px) {
    width: 100%;
    display: flex;
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid var(--preto-100, #000);
    transition: background-color 0.3s;

  &:hover {
    background-color: #000;
    color: #fff;
  }
  }
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  margin-right:30px;
`;

export const CartEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:140px;
`;

export const TitleEmpty = styled.p`
color: #000;
font-family: Thasadith;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal; 
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const DivTotal = styled.div`
  display: flex;
  padding-top:15px;
  padding-bottom:15px;
  justify-content: space-between;
  border-bottom: 1px solid ;
  font-weight: 400;
  font-size: 14px;
`;

export const PrecoAntigo = styled.div`
    text-decoration:line-through;
    font-size:14px;
    color:#888;
    font-weight:300;

`;

export const PrecoDesconto = styled.div`
    font-size:14px;
    color:#41A922;
    font-weight:400;

`;

export const Desconto = styled.div`
  display:flex;
  gap: 10px;
  align-items:center;
`;

export const PrecoOriginal = styled.div`
  font-size:14px;
`;



