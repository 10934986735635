import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CartEmpty,
  Content,
  DivTotal,
  Modal,
  RemoveButton,
  Text,
  TitleEmpty,
  Desconto,
  PrecoAntigo,
  PrecoDesconto,
  PrecoOriginal
} from "./styles";
import { useNavigate } from "react-router-dom";
import { CartContext } from "context/CartContext";
import NoImage from "assets/imgs/nomedia.png";
import { useAuth } from "context/AuthContext";
import { BsTrash } from "react-icons/bs";
import LoginModal from "components/LoginModal";
import { CloseButton } from "components/ModalMobile/styles";

const CartModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { cartItems, removeFromCart } = useContext(CartContext);
  const { id } = useAuth();
  const [isLogged, setIsLogged] = useState(false);
  const handleFinalizarCompra = () => {
    if (id) {
      navigate("/cart", { state: { cartItems } });
    } else {
      setIsLogged(true);

    }
  };
  
  const handleLogin = () => {
    setIsLogged(false);
  };

  useEffect(() => {
    if (id) {
        setIsLogged(false);
    }
  },[id]);

  const handleContinuarComprando = () => {
    navigate("/shop");
  };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const totalDesconto = cartItems.reduce(
    (acc, item) => acc + parseFloat(item.descontoPrice ? item.descontoPrice : item.salePrice),
    0
  );

  const total = cartItems.reduce(
    (acc, item) => acc + parseFloat(item.salePrice),
    0
  );
  const formattedTotal = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(total);

  const formattedTotalDesconto = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(totalDesconto);

  const isCartEmpty = cartItems.length === 0;

  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <CloseButton onClick={handleClose}>X</CloseButton>
        <Content>
          <Text>Carrinho</Text>
          {isCartEmpty ? (
            <CartEmpty>
              <TitleEmpty>Seu carrinho está vazio</TitleEmpty>
              <Button style={{ width: "70%" }} onClick={handleContinuarComprando}>Comprar agora</Button>
            </CartEmpty>
          ) : (
            <div style={{ padding: "30px" }}>
              <div style={{ maxHeight: "45vh", overflowY: "auto" }}>
                {Array.isArray(cartItems) &&
                  cartItems.map((product, index) => (
                    <div
                      key={product.id}
                      style={{
                        display: "flex",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        borderBottom: "0.5px solid #ccc",
                      }}
                    >
                      <img
                        src={product.images[0]?.path || NoImage}
                        width={70}
                        height={"100%"}
                        alt=""
                      />
                      <Box>
                        <span>{product.name.replace(/-/g, "")}</span>
                        <span>Tamanho: {product.size}</span>
                        <span>Cor: {product.color}</span>
                        {product.descontoPrice ? (
                          <>
                          <PrecoAntigo>
                          {formatCurrency((product.salePrice))}
                          </PrecoAntigo>
                          <PrecoDesconto>
                          {formatCurrency((product.descontoPrice))}
                          </PrecoDesconto>
                          </>
                        ) : (
                            <PrecoOriginal>
                            {formatCurrency((product.salePrice))}
                            </PrecoOriginal>
                        )}
                      </Box>
                      <RemoveButton
                        onClick={() => handleRemoveFromCart(product.id)}
                      >
                        <BsTrash></BsTrash>
                      </RemoveButton>
                    </div>
                  ))}
              </div>
              {cartItems.length > 0 && (
                <DivTotal>
                  <span>TOTAL</span>
                  {formattedTotalDesconto ? (
                          <Desconto>
                          <PrecoAntigo>
                          {formattedTotal}
                          </PrecoAntigo>
                          <PrecoDesconto>
                          {formattedTotalDesconto}
                          </PrecoDesconto>
                          </Desconto>
                        ) : (
                            <PrecoOriginal>
                            {formattedTotal}
                            </PrecoOriginal>
                        )}
                </DivTotal>
              )}
              <Button  style={{ backgroundColor: "#fff", color: "#000" }} type="submit" onClick={handleContinuarComprando}>
                Continuar comprando
              </Button>
              <Button
                style={{ backgroundColor: "#000", color: "#fff" }}
                type="button"
                onClick={handleFinalizarCompra}
              >
                Finalizar compra
              </Button>
              {isLogged && (
                <p style={{ color: "red", textAlign: "center" }}>
                  Necessário login para finalizar a compra
                </p>
              )}
            </div>
          )}
        </Content>
      </Modal>
      {isLogged && <LoginModal onLogin={handleLogin}/>}
    </>
  );
};

export default CartModal;
