import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding-left:60px;
  overflow-x: hidden;

  @media (max-width: 1024px) {
  padding:0;
  margin:auto;
  overflow-x: hidden;

  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  width: 40%;
  height: 80vh;
  @media (max-width: 1024px) {
    width: 50%;
    height:auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
  }
`;

export const ProductImageGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ImageGrid = styled.div`
  display: flex;
  margin-bottom: 10px;
  height: 50%;

  img {
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.3s;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProductImage = styled.div`
  width: 100%;
  height: auto;
  margin-bottom:35px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    width: 100%;
    border: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    border: none;
  }
`;

export const DescriptionContainer = styled.div`
  width: 50%;
  flex-direction: column;
  padding:30px;
  @media (max-width: 768px) {
  width: 100%;
  padding: 0px;


  }
`;

export const DescriptionProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    padding: 13px;
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  top: 0;
  right: 3%;
  position: absolute;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 25px;
    top: 5px;
  }

  @media (max-width: 768px) {
    position: absolute;
    top: -50%;
    right: 1%;
    width: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const ProductName = styled.label`
  font-size: 36px;
  padding-top: 5px;

  @media (max-width: 1024px) {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Price = styled.label`
  color: #000;
  font-family: Thasadith;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Category = styled.label`
  font-size: 24px;
  padding-top: 5px;
  color: #c0b6ad;

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;

export const SectionDetails = styled.div`
  display: flex;
  padding: 50px 100px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
`;

export const ModalLoginBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TextDiv = styled.div`
  @media (max-width: 768px) {
    p {
      font-size: 14px;
    }
  }
`;
export const TitleProduct = styled.div`
  color: #000;
  font-family: Abhaya Libre;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height:10px;
`;

export const Tamanho = styled.div`
  color: #000;
  /* Texto 18 px light */
  font-family: Thasadith;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const InfoProduct = styled.div`
  color: #000;
  font-family: Thasadith;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Text = styled.div`
  display: flex;
  color: #000;
  font-family: Thasadith;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  border-radius: 50%;
  ${({ border }) => (border ? "border: 1px solid #000" : "none")}
`;

export const DescriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;

export const Menu = styled.div`
  display: flex;
  padding: 10px;

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const MenuItem = styled.a`
  margin: auto;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  font-size: 14px;
  font-family: Thasadith;

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const MenuItemSize = styled.a`
  margin: auto;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  font-size: 14px;
  font-family: Thasadith;
  font-style: normal;
  line-height: normal;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuItemSizeNone = styled.a`
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    bottom: 15%;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`;

export const Section = styled.div`
  width: 90%;
  padding: 10px 30px;
  font-size: 14px;
  font-family: Thasadith;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DescriptionButton = styled.div`
  display: flex;
  height: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid var(--preto-100, #000);
  transition: background-color 0.3s;
  background-color: #000;
  color: #fff;
  cursor:pointer;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

export const DescriptionButtonDisabled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  border-radius: 50px;
  margin: 0 20px;
  font-weight: 400;
  cursor: not-allowed;
  transition: background-color 0.3s;
  opacity: 0.3;
  border: 1px solid var(--preto-100, #000);
  
 
`;

export const Desconto = styled.p`
  padding: 13px;
  margin:0;

`;
export const PrecoAntigo = styled.p`
    font-size:17px;
    text-decoration:line-through;
    padding: 0;
    margin:0;
    color:#888;
    font-weight:400;

`;

export const PrecoOriginal = styled.p`
    padding: 0;
    margin:0;

`;

export const PrecoDesconto = styled.p`
    font-size:20px;
    padding: 0;
    margin:0;
    color:#41A922;
    font-weight:700;

`;
