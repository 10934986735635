import React from 'react';
import {
    ItemStore,
    ItemTop,
    Price,
    ProductName,
    LineSeparator,
    ProductInfoOverlay,
    ProductInfoItem,
    ProductBrand,
    InfoText,
    InfoValue,
    Desconto,
    PrecoOriginal,
    PrecoAntigo,
    PrecoDesconto
  } from "./styles";
import ProductImage from 'components/ProductImage';
const ProductItem = ({
  key,
  product,
  itemSize,
  hoveredProductId,
  mainPhotoIndex,
  handleMouseEnter,
  handleMouseLeave,
  formatPrice,
  desconto,
}) => {

  const mainImagePath = (product.images && product.images[mainPhotoIndex])?.path || 'assets/imgs/backgroundProducts.jpg'; // Valor padrão se não houver imagem
  const hoveredImagePath = (product.images && product.images.length > 1)
              ? (mainPhotoIndex === 0 ? product.images[1]?.path : product.images[0]?.path)
              : (product.images && product.images[0]?.path) || 'assets/imgs/backgroundProducts.jpg'; 

  const imageLowPath = (product.images && product.images[mainPhotoIndex] && product.images[mainPhotoIndex].path_low && product.images[mainPhotoIndex].path_low !== "")
  ? product.images[mainPhotoIndex].path_low
  : "assets/imgs/backgroundProducts.jpg";
  return (

    <ItemStore
      key={product.id}
      size={itemSize}
      onMouseEnter={() => handleMouseEnter(product.id)}
      onMouseLeave={handleMouseLeave}
      data-testid="item-store"
    >
      <ItemTop size={itemSize}>
        {hoveredProductId === product.id && (
          <ProductInfoOverlay size={itemSize}>
            {product.group !== "BOLSA" && product.group !== "MALA" && (
              <ProductInfoItem>
                <InfoText>Tamanho</InfoText>
                <InfoValue>{product.size}</InfoValue>
              </ProductInfoItem>
            )}
            <ProductInfoItem>
              <InfoText>Estado</InfoText>
              <InfoValue>{product.conditionPart}</InfoValue>
            </ProductInfoItem>
          </ProductInfoOverlay>
        )}
        <ProductImage
          image={mainImagePath}
          hoveredImage={hoveredImagePath}
          altImage={product.alt}
          id={product.id}
          imageLow={imageLowPath}
        />
        <ProductBrand>
          {product.brand?.replace(/-/g, "")}
        </ProductBrand>
        <ProductName>
          {product.name?.replace(/-/g, "")}
        </ProductName>
        <LineSeparator />
        <Price>
            {desconto ? (
                 <Desconto>
                    <PrecoAntigo data-testid="preco_antigo">
                    R$ {formatPrice(parseFloat(product.salePrice))}
                    </PrecoAntigo>
                    <PrecoDesconto data-testid="preco_desconto">
                    R$ {formatPrice(parseFloat(product.descontoPrice))}
                    </PrecoDesconto>
                 </Desconto>
            )
            : (
                <PrecoOriginal data-testid="preco_original">
                R$ {formatPrice(parseFloat(product.salePrice))}
                </PrecoOriginal>
            )}
          
        </Price>
      </ItemTop>
    </ItemStore>
  );
};
export default ProductItem;
